import * as React from "react"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import { JsonDebugger } from "../../components/json-debugger"
import { Button, Checkbox, Form, Input, Typography } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { Link } from "gatsby"
import { loginForm } from "./account.module.less"
import { StoreContext } from "../../context/store-context"

const { Title, Link: AntLink } = Typography

const LogoutPage: React.FC = (data) => {
  const { logout, loading, customer } = React.useContext(StoreContext)

  return (
    <Layout>
      <Seo title="Logout" />
      <Title>Logout</Title>
      <Button type="primary" onClick={() => logout()}>
        Logout
      </Button>
    </Layout>
  )
}

export default LogoutPage
